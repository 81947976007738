export const Links = {
  WALLET_RELEASES: "https://github.com/vegaprotocol/vegawallet-desktop/releases",
  WALLET_GUIDE:
    "https://docs.vega.xyz/docs/mainnet/tools/vega-wallet",
  SUSHI_PAIRS: "https://analytics.sushi.com/pairs/",
  SUSHI_ONSEN_MENU: "https://app.sushi.com/farm",
  SUSHI_ONSEN_WHAT_IS:
    "https://docs.sushi.com/products/yield-farming/what-is-onsen",
  SUSHI_ONSEN_FAQ: "https://docs.sushi.com/faq-1/onsen-faq",
  FEEDBACK: "https://github.com/vegaprotocol/feedback/discussions",
  GITHUB: "https://github.com/vegaprotocol/token-frontend",
  DISCORD: "https://vega.xyz/discord",
  STAKING_GUIDE:
    "https://docs.vega.xyz/docs/mainnet/concepts/vega-chain/#staking-on-vega",
};
