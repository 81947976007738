export const NetworkParams = {
  ETHEREUM_CONFIG: "blockchains.ethereumConfig",
  REWARD_ASSET: "reward.asset",
  REWARD_PAYOUT_DURATION: "reward.staking.delegation.payoutDelay",
  GOV_UPDATE_MARKET_REQUIRED_MAJORITY:
    "governance.proposal.updateMarket.requiredMajority",
  GOV_UPDATE_MARKET_REQUIRED_PARTICIPATION:
    "governance.proposal.updateMarket.requiredParticipation",
  GOV_NEW_MARKET_REQUIRED_MAJORITY:
    "governance.proposal.market.requiredMajority",
  GOV_NEW_MARKET_REQUIRED_PARTICIPATION:
    "governance.proposal.market.requiredParticipation",
  GOV_ASSET_REQUIRED_MAJORITY: "governance.proposal.asset.requiredMajority",
  GOV_ASSET_REQUIRED_PARTICIPATION:
    "governance.proposal.asset.requiredParticipation",
  GOV_UPDATE_NET_PARAM_REQUIRED_MAJORITY:
    "governance.proposal.updateNetParam.requiredMajority",
  GOV_UPDATE_NET_PARAM_REQUIRED_PARTICIPATION:
    "governance.proposal.updateNetParam.requiredParticipation",
  VALIDATOR_DELEGATION_MIN_AMOUNT: "validators.delegation.minAmount",
};
