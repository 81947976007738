export const HandUp = () => (
  <svg className="icon" viewBox="0 0 16 16">
    <path
      d="M13.65,6.19c-0.34,0-0.64,0.11-0.88,0.29C12.6,6,12.09,5.64,11.48,5.64
			c-0.41,0-0.78,0.16-1.03,0.42c-0.23-0.37-0.67-0.63-1.19-0.63c-0.57,0-1.05,0.31-1.25,0.74L8,5.55V1.5C8,0.67,7.33,0,6.5,0
			S5,0.67,5,1.5v6.61C4.42,7.7,3.45,6.9,2.52,6.81C0.96,6.67,0.7,7.88,1.28,8.13c1.54,0.67,2.99,2.68,3.7,3.95
			C5.89,14.05,6.07,16,9.86,16c2.09,0,3.43-0.61,4.22-2.12C14.72,12.64,15,10.79,15,8.17V7.38C15,6.73,14.4,6.19,13.65,6.19z"
    />
  </svg>
);
